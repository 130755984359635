.data-table{
  border: solid 1px black;
  border-collapse: collapse;
  padding: 8px;
  margin: 10px;
}

.data-table th, td{
  border: solid 1px black;
  border-collapse: collapse;
  padding: 8px;
}

.minus-Sign{
  border: solid 0 black;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin: 8px;
  cursor: not-allowed;
}

.database-select-option{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 10px 0 10px;
}